/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'shape-mask-gradient': {
    width: 125,
    height: 125,
    viewBox: '0 0 125 125',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M100.355 4.932C92.058 1.687 77.149.13 62.5 0 47.85.13 32.942 1.687 24.775 4.803c-17.372 6.75-21.39 25.96-21.39 25.96C.79 41.666-.117 52.699.013 62.434c-.13 9.865.648 20.898 3.37 31.802 0 0 4.019 19.21 21.39 25.96 8.168 3.115 23.077 4.673 37.726 4.804 14.65-.131 29.558-1.559 37.724-4.804 17.373-6.75 21.39-25.96 21.39-25.96 2.593-10.904 3.502-21.937 3.372-31.672.13-9.865-.648-20.898-3.241-31.671 0 0-4.02-19.21-21.39-25.96z" _fill="url(#svgicon_shape-mask-gradient_a)"/><linearGradient id="svgicon_shape-mask-gradient_a" x1="4.361" y1="0" x2="73.504" y2="75.814" gradientUnits="userSpaceOnUse"><stop stop-color="#B65DFC"/><stop offset="1" stop-color="#5863FC"/></linearGradient>'
  }
})
